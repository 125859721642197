import React, { Component } from "react";
import { Alert, Popover, Form, Input, Button } from "antd";
import "./login.scss";
import axios from "axios";

class EELoginForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      alert: false,
      company: "",
      url: "",
    };
  }

  getUrl = async (trimmedCompany) => {
    const encodedCompany = encodeURIComponent(trimmedCompany);
    try {
      let res = await axios.get(
        `${process.env.REACT_APP_API_BASE}/v1/employers/benAdminUrl?alias=${encodedCompany}`
      );

      this.setState({
        url: res.data.benAdminUrl,
      });
      this.openUrl();
    } catch (e) {
      this.setState({
        url: "error",
      });
      this.openUrl();
    }
  };

  openUrl = () => {
    const { url } = this.state;
    if (url === "error" || url === "") {
      this.setState({
        alert: true,
      });
    }
    if (url !== "error" && url !== "") {
      window.open(url, "_self");
    }
  };

  onChangeName = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
      alert: false,
    });
  };

  onSubmit = () => {
    const {company = ''} = this.state;
    this.getUrl(company.trim());
  };

  disableButton = () => {
    const { company } = this.state;
    if (company !== "") {
      return false;
    }
    return true;
  };

  render() {
    const { company, alert } = this.state;
    const alertMsg = (
      <span className='alert-box'>
        We are having trouble finding your company. Please contact your admin or{" "}
        <a href='mailto:support@lumity.com'>email us</a>.
      </span>
    );

    return (
      <div className='action-box'>
        <Form>
          {alert === true && (
            <Alert
              message={alertMsg}
              type='success'
              className='alert-box-outer'
            />
          )}
          <div className='label-margin'>
            <label className='label'>Your Company</label>
          </div>

          <Form.Item>
            <Input
              type='text'
              name='company'
              size='large'
              value={company}
              onChange={this.onChangeName}
              required='required'
            />
          </Form.Item>

          <div className='box-footer'>
            <Button
              type='primary'
              htmlType='submit'
              size='large'
              disabled={this.disableButton()}
              onClick={this.onSubmit}
            >
              Submit
            </Button>
          </div>

          <Popover
            content={
              <span>
                If you can't find or don't know your company, please contact
                your HR Administrator or contact us at 1-844-2-LUMITY
              </span>
            }
            placement='right'
          >
            <div className='need-help'>
              <span className='dashed-line'>Need Help?</span>
            </div>
          </Popover>
        </Form>
      </div>
    );
  }
}

export default EELoginForm;
