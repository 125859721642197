import * as React from "react";
import Layout from "antd/lib/layout";
import "./style.scss";

const PageFooter = () => {
  return (
    <Layout.Footer className="page-footer">
      <span className="ext-link">© 2020 Lumity, Inc.</span>

      <a
        href="https://www.lumity.com/terms-of-service"
        className="ext-link"
        target="_blank"
        rel="noopener noreferrer"
      >
        Terms
      </a>

      <a
        href="https://www.lumity.com/privacy-policy"
        className="ext-link"
        target="_blank"
        rel="noopener noreferrer"
      >
        Privacy
      </a>

      <a
        href="https://www.lumity.com/insurance-licensing"
        className="ext-link"
        target="_blank"
        rel="noopener noreferrer"
      >
        Insuarance Licenses
      </a>
    </Layout.Footer>
  );
};

export default PageFooter;
