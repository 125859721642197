import React from "react";
import Row from "antd/lib/row";
import LumityLogo from "./images/lumity-logo.svg";
import EELoginForm from "./Login/EELoginForm";
import PageFooter from "./PageFooter/PageFooter";
import "antd/dist/antd.css";
import "./App.css";

function App() {
  return (
    <div className="App">
      <div className="page-login">
        <div className="login-container">
          <Row className="center-logo">
            <img className="logo" src={LumityLogo} alt="Lumity Logo" />
          </Row>

          <div className="panel--white">
            <EELoginForm />
          </div>
        </div>
      </div>

      <PageFooter />
    </div>
  );
}

export default App;
